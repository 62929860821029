@import "initcss";
@import "fonts";
@import "colors";
@import "mixins";
@import "contenpage";
.landingpage{
font-family: "Red Hat Display", sans-serif;
font-size:16px;
width:100%;
#lmi{
  width:100%;
  height:100%;
  overflow:hidden;
}
/*
  cursor:  url("../cursor/cursornn.png"), auto;
a{
  cursor:  url("../cursor/cursor-link-nn.png"), auto;

}
*/

  .mousemover{
    display:none;

    pointer-events: none;
   @include desk-small{
      display:block;
    }

background-image: url("../cursor/cursor-circle.svg");
  width:145px;
height:143px;
background-repeat: no-repeat;
position: fixed;
    z-index:999999;
}

  a{


  }
}

.contentarea{
grid-area: content;


  @include desk-small {

  }

}
.maincontentframe{
  overflow-y:auto;
overflow-x: hidden;
  width:100%;
  height:100vh;
  display:grid;


  grid-template-columns: 40px 1fr;
  @include tablet-por{
    grid-template-columns: 60px 1fr;

  }
 @include tablet-por{
    grid-template-columns: 58px 1fr;

  }
@include desk-small{
  overflow-y:hidden;
}
  grid-template-rows: auto 1fr;
  grid-template-areas:

  'header header'
  'navi content'

;

  header{
    grid-area: header;
    align-self: flex-start;
    z-index:0;
    padding-inline-start:20px;
    a{

    }
@include tablet-por{
  padding-inline-start:40px;
}
  }





    .contentarea {
      @include tablet-por{
        margin-top:75%;
      }
      @include tablet-max{
        margin-top:5%;
      }
width:100%;
      @include desk-small {
        max-width: 1400px;
margin-top:0;
        display:grid;
        grid-template-columns: 6.35714% repeat(11,6.35714%);
       gap:0 30px;
      }

      @media screen and (min-width:1700px){
        max-width:unset;
      }
    }
@include tablet-por {


}
@include tablet-max {


}



  .navigationframe{
    grid-area: navi;position:fixed;
height:100%;
z-index:9999999;

@include tablet-por{

}

@media screen and (min-width:1200px){

  bottom:40px;
  ul {
    bottom: 0% !important;
    z-index:999999;
    @media screen and (max-width: 766px) and (min-height: 550px) {
      bottom: -2% !important;


    }



    @media screen and (max-width: 766px) and (min-height: 700px) {
      bottom: 4% !important;
    }
    @media screen and (max-width: 766px) and (min-height: 800px) {
      bottom: 5% !important;


    }


    @media screen and (min-width:1400px) {
      bottom:-25px !important;
      left: 29px !important;
    }
  }


}



  }


.navilist{
list-style-type: none;

  -webkit-transform:
          rotate(270deg);-ms-transform: rotate(270deg);transform: rotate(270deg);
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;

  width:100%;
  position:absolute;
  bottom:-10px;

  --linkfontsize:8px;
  --linkeLineheight:10px;
  --link-letterspacing:3px;


  @include tablet-por{
    --linkfontsize:12px;
    --linkeLineheight:16px;
    --link-letterspacing:4px;

    bottom:75px;


  }@include tablet-max{
    --linkfontsize:15px;
    --linkeLineheight:20px;
    --link-letterspacing:5px;
       bottom:0px!important;

  }
@include desk-small{

  bottom:30%;
  left:20px;
  }
@media screen and (min-width:1700px){
  bottom:200px;
}
  @media screen and (max-height:500px){




     }





li{

 -webkit-padding-start: 20px;
  padding-inline-start: 20px;
  a{
    text-transform: uppercase;
    color:#000000;
    font-size: var(--linkfontsize);
    line-height: var(--linkeLineheight);
    letter-spacing: var(--link-letterspacing);
    font-weight:500;
    text-decoration:none;
    display:inline-block;

    &:hover{
    color:#929292;

    }
  }
}
li:first-child{
  padding-inline-start: 0px;

}
}




}

.backgroundsliderframe{
  position:fixed;
  top:0%;
  left:0;
  width:100%;
  height:100%;
  z-index:-1;

}
@keyframes slider {

  from{
    background-position: 0 0px;
  }
  to{
    background-position: 0 100%;
  }

}

.contentpage{





}

.backgroundimage{

  width:100%;
  height:100%;
  -webkit-animation: slider linear infinite;
  animation: slider linear infinite;

  -webkit-animation-duration: 100s;

  animation-duration: 100s;

  @include tablet-max {
    -webkit-animation-duration: 150s;
    animation-duration: 150s;
  }
  background-repeat: no-repeat;
  background-position: 0 0px;
  background-attachment: fixed;
  background-size: cover;
}
.logo{

  --max-width:305px;
  --margin-bottom:445px;
  --padding-top:20px;
@include tablet-por{
  --max-width: 391px;
  --padding-top:40px;
  --margin-bottom:0px;
}
@include tablet-max{
  --max-width: 400px;
  --padding-top:45px;
  --margin-bottom:0px;
}
@include desk-small{
  --max-width: 430px;
  --margin-bottom:0px;

}
@media screen and (min-width:1600px){
  --max-width: 550px;

}
@media screen and (min-width:2000px){
  --max-width: 650px;

}


  padding-block-start: var(--padding-top);

  z-index:1;
  margin-block-end: var(--margin-bottom);
  max-width:var(--max-width);

}


.contentpage{

  .navilist{
@include tablet-max{
  bottom:0!important;
}
  }

  .maincontentframe{
    position:relative;
    left:0;

overflow: scroll!important;

    @include tablet-por{

    }
  }
  --headline-fontsize:1.5rem;
  --headline-marginbottom: 40px;
  --content-fontsize: 0.938rem;
  --letterspacing:0.8px;


  @include tablet-por{
  --headline-fontsize: 1.875rem;
    --content-fontsize: 1.188rem;
    --letterspacing:1px;
}
  .normcontent{
    margin-inline-start: 20px;
    padding-block-end: 40px;
position:absolute!important;
padding-inline-end: 30px;
top:442px;
    @include tablet-por{
  margin-inline-start: 40px;
  margin-block-start: 0px;
  padding-block-end: 120px;
      top:422px;

}
    @include tablet-max{
      margin-block-start:0px;
      padding-inline-end: 100px;

    }
    @include desk-small{
    margin-top:10px;
      margin-left:7%;
    }
  }

  h1{
    font-weight:500;
    margin-block-end:var(--headline-marginbottom);
    text-transform: uppercase;
    line-height:48px;
        font-size: var(--headline-fontsize);
  }
  p{
    letter-spacing: var(--letterspacing);
    font-size: var(--content-fontsize);
    line-height: 25px;
    margin-block-end:25px;
  color:black;
  }
  a{
    text-decoration: none;
    color:inherit;
  }



}
.contentpage{
  .maincontentframe{

  }
}