
.contentpage {
h1{
  letter-spacing: 3px;
}
  .backgroundimage {

  }

  .navigationframe {

  position: fixed !important;
  left: 0;
  z-index:999999;

    ul {
      bottom: 0% !important;
      z-index:999999;
      @media screen and (max-width: 766px) and (min-height: 550px) {
        bottom: -2% !important;


      }



      @media screen and (max-width: 766px) and (min-height: 700px) {
        bottom: 4% !important;
      }
      @media screen and (max-width: 766px) and (min-height: 800px) {
        bottom: 5% !important;


      }


    @media screen and (min-width:1400px) {
      bottom: 18px !important;
      left: 29px !important;
    }
    }
    }

header{
  position:fixed;
  width:100%;
  background-color: #ffffff;
  z-index:0;
top:0;
  left:0;
  padding-block-end: 20px;

  height:60px!important;
  @include tablet-por{
  height: 120px!important;
  }
}
}