@mixin roundedbox($round:5px,$color:#ffffff)
{
  position:relative;
  @include border-radius($round);
  color:$color;

  .content{

  }

}
@mixin textShadow(){
  text-shadow: rgba(0,0,0,.6) 0 0.1em 0.1em;
}
@mixin bs(){
  box-sizing:border-box;
}
@mixin boxShadow(){
  box-shadow: 6px 4px 26px 0px rgba(0,0,0,0.59);
  -webkit-box-shadow: 6px 4px 26px 0px rgba(0,0,0,0.59);
  -moz-box-shadow: 6px 4px 26px 0px rgba(0,0,0,0.59);
}
@mixin setTransition($element:all,$time:0.3s){
  -webkit-transition: $element $time ease;
  -o-transition:$element $time ease;
  transition:$element $time ease;
}

@mixin fontSize($fontsize:16){

  $param: $fontsize * 0.0625;
  $fontsize:$param+rem;
  font-size: $fontsize;

}@mixin lineHeight($lh:16){

  $param: $lh * 0.0625;
  $lineheight:$param+rem;
  line-height: $lineheight;
}

@mixin sitePaddingComplete($padding:25px) {
  padding-left: $padding;
  padding-right: $padding;
}
@mixin sitePaddingLeft($padding:25px) {
  padding-left: $padding;
}
@mixin sitePaddingRight($padding:25px){

  padding-right:$padding;
}
@mixin verticalMarginComplete($margin:77px){

  margin-top:$margin;
  margin-bottom:$margin;

}

@mixin buildFontSizes($fs:20,$lh:30){

  @include fontSize($fs);
  @include lineHeight($lh);

}

@mixin centerAbsElement(){
  position:absolute;
  left:50%;
  top:50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
@mixin topMargin($margin:50px){

  margin-top:$margin;

}@mixin bottomMargin($margin:50px){

  margin-bottom:$margin;


}
@mixin topMargin($margin:50px){

  margin-top:$margin;

}
@mixin vMargin($margintop:50px,$marginbottom:50px) {
  margin-bottom: $marginbottom;
  margin-top: $margintop
}
@mixin overlayfigure() {
  position: relative;
  margin-bottom:25px;

}

@mixin tablet-por {
  @media(min-width: 767px) {
    @content
  }
}
@mixin phone{
  @media(max-width: 766px){@content}
}


@mixin tablet-max{
  @media(min-width: 1024px){@content}
}
@mixin desk-small{
  @media(min-width: 1200px){@content}
}
@mixin desk-max{
  @media(min-width: $maxwidth){@content}
}

/*Margin Classes

 */

.margin-full{

  margin-top:50px;
  margin-bottom:50px;
  @include tablet-por{
    margin-top:75px;
    margin-bottom:75px;
  }
  @include tablet-max{
    margin-bottom:100px;
    margin-top:100px;

  }

}.margin-top{

  margin-top:50px;
  @include tablet-por{
    margin-top:75px;

  }
  @include tablet-max{
    margin-top:100px;

  }

}.margin-bottom{

  margin-bottom:50px;
  @include tablet-por{
    margin-bottom:75px;

  }
  @include tablet-max{
    margin-bottom:100px;

  }

}
 @mixin mixmarginfull{

   margin-top:50px;
   margin-bottom:50px;
   @include tablet-por{
     margin-top:75px;
     margin-bottom:75px;
   }
   @include tablet-max{
     margin-bottom:100px;
     margin-top:100px;

   }
 }
 @mixin mixmargintop{

   margin-top:50px;
   @include tablet-por{
     margin-top:75px;
   }
   @include tablet-max{
     margin-top:108px;
   }
 }
 @mixin mixmarginbottom{


   margin-bottom:50px;
   @include tablet-por{
     margin-bottom:75px;
   }
   @include tablet-max{
     margin-bottom:108px;

   }
 }
.padding-top{

  padding-top:50px;
  @include tablet-por{
    padding-top:75px;

  }
  @include tablet-max{
    padding-top:100px;

  }

}
.padding-bottom {

  padding-bottom: 50px;
  @include tablet-por {
    padding-bottom: 75px;

  }
  @include tablet-max {
    padding-bottom: 100px;

  }
}
  .padding-full{

    padding-top:50px;
    padding-bottom:50px;
    @include tablet-por{
      padding-top:75px;
      padding-bottom:75px;
    }
    @include tablet-max{
      padding-bottom:100px;
      padding-top:100px;

    }

  }

