@import "initcss";
@import "fonts";
@import "colors";
@import "mixins";

.logoframe{

position:relative;
  img{

  }
@include desk-small{
grid-column: 1 / 12;
  height:auto;
}



}
.logowrapper{
  bottom:72px; //height:740px;
  z-index:999999999999999999999;

  @include tablet-por{
  bottom:-70px;
}
  @include tablet-max{
    bottom: 60px;
  }
@include desk-small{
  position:relative!important;
  margin-inline-start: 9%;
  bottom:0;
}

  @media screen and (min-width:2000px){
    z-index:999999999999999999999;

    position:absolute!important;
    bottom:-180px!important;
    right:-80px;
  }
}
.logomasswerk{
  --max-width: 208px;
  --margin-top:126px;
  --margin-bottom:42px;
  --margin-left:calc(100% - 226px);
  --margin-right:18px;


  @include tablet-por{
    --max-width: 310px;
    --margin-bottom:60px;
    --margin-left:calc(100% - 457px);

  } @include desk-small{
    --max-width: 403px;
    --margin-bottom:70px;
    --margin-left:calc(100% - 490px);
  }
  @include desk-small{
    --margin-top:46px;
  }
  -webkit-margin-start: var(--margin-left);
  margin-inline-start: var(--margin-left);

  -webkit-margin-before: var(--margin-top);

  margin-block-start: var(--margin-top);
  -webkit-margin-after: var(--margin-bottom);
  margin-block-end: var(--margin-bottom);

  img {
    max-width: var(--max-width);


  }
}
.logoprojekt{
  --max-width: 232px;
  --margin-bottom:42px;
--margin-left:10px;

  @include tablet-por{
    --max-width: 346px;
  }

@include desk-small{
  --max-width: 450px;

  --margin-bottom:170px;
}

  -webkit-margin-after: var(--margin-bottom);
  margin-block-end: var(--margin-bottom);
  margin-inline-start: var(--margin-left);
  img {
    max-width: var(--max-width);
  }

@include desk-small{
  margin-left:400px;
}
@media screen and (min-width:1400px){
  margin-left:500px;
}
@media screen and (min-width:1700px){
  margin-left:55%;
  margin-top:80px!important;
}

}.logoladenbau{
   --max-width: 89px;
   --margin-left:calc(100% - 132px);

   @include tablet-por{
     --max-width: 133px;
     --margin-left:calc(100% - 208px);
     position:relative;
     top:-100px;
   }
@include desk-small{
  --max-width: 173px;
  --margin-left:calc(100% - 223px);
top:-250px;
}
   @media screen and (min-width:1400px) {
     --margin-left:calc(100% - 173px);
     top:-230px;

   }
   -webkit-margin-start: var(--margin-left);

   margin-inline-start: var(--margin-left);
   img {
     max-width: var(--max-width);
   }
 }

 .logowrapper{
  position:absolute;
   width:100%;

 }
 .imagebody {
   .maincontentframe {
     overflow: hidden;

   }
 }