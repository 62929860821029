@import "initcss";
@import "fonts";
@import "colors";
@import "mixins";

.optionlist{
  --margin-left:49px;
  --listcolor: #1A1A18;
  --listfontsize: 21px;
  --listlinewidth:34px;
  --listlineheight:25px;
  --listbeforelineheight:1.54px;
  --listbottompostion: 5px;
  --listleftpostion: -45px;
  --listpaddingend: 40px;
  --lileft: 40px;
  --margin-top:65px;

  @include tablet-por{
    --listfontsize: 30px;
    --listlinewidth:50px;
    --listlineheight:37px;
    --listbeforelineheight:2.3px;
    --listbottompostion: 9px;
    --listleftpostion: -72px;
    --listpaddingend: 60px;
    --lileft: 70px;
    --margin-left:53px;
  }@include tablet-max{
     --listfontsize: 40px;
     --listlinewidth:66px;
     --listlineheight:48px;
     --listbeforelineheight:3px;
     --listbottompostion: 10px;
     --listleftpostion: -72px;
     --listpaddingend: 60px;
     --lileft: 70px;
   }
  @include desk-small{
    --listpaddingend: 0px;
    --margin-left: 0;
    grid-column-start: 2;
  }
  @media screen and (min-width:1700px){
    --margin-left: -21%;
  }
  @media screen and (min-width:1900px){
    --margin-left: -24%;
  }

  margin-block-start: var(--margin-top);
margin-inline-start: var(--margin-left);
  list-style: none;
  padding-left:0;
  color:var(--listcolor);
  font-size: var(--listfontsize);
  line-height:var(--listlineheight);
  letter-spacing: 2px;

  li{
    position:relative;
    left:var(--lileft);

    &:before{
      content:"";
      width:var(--listlinewidth);
      height:var(--listbeforelineheight);
      background-color: var(--listcolor);
      display:block;
      position:absolute;
      bottom:var(--listbottompostion);
      left: var(--listleftpostion);
      padding-inline-end:var(--listpaddingend);

    }

  }


}
